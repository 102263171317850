<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
     clipped
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <div style="text-align: center;">
      <img
            
            style="margin-top:5px"
            src="/logoapunto.png"
            alt="Menu"
            height="150px"
        >
    </div>

    <v-divider class="mb-2" />

    <v-list
      dense
      expand
      nav
    >
        <div v-for="child in items" :key="child.to">
          <v-list-item
            :to="child.to"
            
            link
        
          >
            <v-list-item-icon>
              <v-icon v-text="child.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
    </v-list>

    <template v-slot:append>
     
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
 
 
  export default {
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      
    },

    data: () => ({
  
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'servicios',
          to: '/admin',
        },
        {
          icon: 'mdi-account',
          title: 'reservas',
          to: '/admin/reservas',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          
        }
      },
    },
  }
</script>

<style scoped>
.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before{
    opacity: 0;
}
</style>
